<template>
  <header-component></header-component>
  <div class="container-full bg-blue-line">
    <div class="container-page">
      <div class="found w-screen">
        <div class="container-main-home">
          <div class="display">
            <img
              class="imagen-page md:w-1/2"
              src="./../assets/img/imagen.svg"
              alt="logo"
            />
            <div class="box-parrafo">
              <div class="card sm:w-auto md:w-full">
                <div class="card-body">
                  <p class="title-form">Bienvenido/a</p>
                  <br />
                  <p class="subtitle-form">
                    Para poder continuar identifíquese con Clave Única o Clave
                    Tributaria en los siguientes botones.
                  </p>

                  <br />
                  <div class="container-centrar">
                    <div class="flex-config-buttons">
                      <a
                        @click="cuCall()"
                        class="btn-cu btn-m btn-color-estandar cl-claveunica-image"
                        title="Este es el botón Iniciar sesión de ClaveÚnica"
                      >
                      </a>
                        <button
                         @click="ctCall()"
                         :disabled="disabledBtn"
                         class="btn-ct btn-color-estandar"
                         title="Este es el botón Iniciar sesión de Clave Tributaria"
                       >
                         <span class="cl-logo-ct-1"></span>
                       </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <br />
  <fotter-component></fotter-component>
</template>

<script>
// @ is an alias to /src
// Parámetro "form_id" es esperado para enviar parámetro en URL a Clave Unica
import FotterComponent from "@/components/FotterComponent";
import HeaderComponent from "@/components/HeaderComponent";

export default {
  name: "HomeView",
  components: {
    HeaderComponent,
    FotterComponent
  },
  data() {
    return {
      disabledBtn: false,
      cuUrl:
        process.env.VUE_APP_CU_URL +
        "&redirect_uri=" +
        process.env.VUE_APP_REDIRECT_URL,
      ctUrl:
        process.env.VUE_APP_URL_CT +
        "&redirect_uri=" +
        process.env.VUE_APP_REDIRECT_CT_URL
    };
  },
  mounted() {
    let params = this.$route.query;

    let state = this.$uuid.v4();
    localStorage.setItem("state", state);
    localStorage.removeItem("tributaria");

    if (params.form_id !== null && params.form_id !== undefined) {
      localStorage.setItem("form_id", params.form_id);
    }

    let allParametersExceptFormId = Object.fromEntries(
      Object.entries(params).filter(([key]) => key !== "form_id")
    );

    if (
      Object.keys(allParametersExceptFormId).length !== 0 &&
      allParametersExceptFormId.constructor === Object
    ) {
      localStorage.setItem("params", JSON.stringify(allParametersExceptFormId));
    }

    this.cuUrl = this.cuUrl + state;

    this.ctUrl = this.ctUrl + state;
  },
  methods: {
    ctCall: function () {
      this.disabledBtn = true;
      localStorage.setItem("tributaria", true);
      this.disabledBtn = false;
      window.location.href = this.ctUrl;
    },
    cuCall: function () {
      this.disabledBtn = true;
      localStorage.setItem("tributaria", false);
      this.disabledBtn = false;
      window.location.href = this.cuUrl;
    }
  }
};
</script>

<style>
@import "@/assets/styles/cu.min.css";
@import "@/assets/styles/styles.css";
</style>
