<template>
  <footer class="flex flex-col overflow-hidden" data-v-ff9c5d02="">
    <div class="bg-lightgray px-8 dark:bg-blackAlt" data-v-ff9c5d02="">
      <div
        class="container mx-auto grid justify-items-center gap-10 py-10 lg:grid-cols-4"
        data-v-6c463fe2=""
        data-v-ff9c5d02=""
      >
        <a
          href="https://www.portaltransparencia.cl/PortalPdT/directorio-de-organismos-regulados/?org=AE033"
          target="_blank"
          class="btn-group btn-group-gob dark:border-2"
          data-v-6c463fe2=""
        >
          <div
            class="flex flex-col bg-transparent"
            data-v-6c463fe2=""
            data-v-ff9c5d02=""
          >
            <span class="button-title" data-v-6c463fe2="" data-v-ff9c5d02=""
              >Transparencia Activa</span
            >
            <span class="button-content" data-v-6c463fe2="" data-v-ff9c5d02=""
              >Ley de Transparencia</span
            >
          </div>
        </a>
        <a
          href="https://www.portaltransparencia.cl/PortalPdT/directorio-de-organismos-regulados/?org=AE033"
          target="_blank"
          class="btn-group btn-group-gob dark:border-2"
          data-v-6c463fe2=""
        >
          <div
            class="flex flex-col bg-transparent"
            data-v-6c463fe2=""
            data-v-ff9c5d02=""
          >
            <span class="button-title" data-v-6c463fe2="" data-v-ff9c5d02=""
              >Solicitud de Información</span
            >
            <span class="button-content" data-v-6c463fe2="" data-v-ff9c5d02=""
              >Ley de Transparencia</span
            >
          </div>
        </a>
        <a
          href="https://www.leylobby.gob.cl/instituciones/AE033"
          target="_blank"
          class="btn-group btn-group-gob dark:border-2"
          data-v-6c463fe2=""
        >
          <div
            class="flex flex-col bg-transparent"
            data-v-6c463fe2=""
            data-v-ff9c5d02=""
          >
            <span class="button-title" data-v-6c463fe2="" data-v-ff9c5d02=""
              >Solicitud de Audiencia</span
            >
            <span class="button-content" data-v-6c463fe2="" data-v-ff9c5d02=""
              >Ley del Lobby</span
            >
          </div>
        </a>
        <a
          href="https://reformatributaria.cl/"
          target="_blank"
          class="btn-group btn-group-gob dark:border-2"
          data-v-6c463fe2=""
        >
          <div
            class="flex flex-col bg-transparent"
            data-v-6c463fe2=""
            data-v-ff9c5d02=""
          >
            <span class="button-title" data-v-6c463fe2="" data-v-ff9c5d02=""
              >Reforma Tributaria</span
            >
            <span
              class="button-content"
              data-v-6c463fe2=""
              data-v-ff9c5d02=""
            ></span>
          </div>
        </a>
      </div>
    </div>
    <div class="bg-[#001c41] lg:pt-10" data-v-ff9c5d02="">
      <div
        class="container mx-auto justify-between space-y-10 bg-[#001c41] px-10 pt-10 lg:flex lg:space-y-0 xl:px-0"
        data-v-ff9c5d02=""
      >
        <div
          class="flex w-full flex-col items-start space-y-24 lg:w-6/12 lg:justify-between xl:w-3/12"
          data-v-ff9c5d02=""
        >
          <div
            class="lg:gap flex flex-col items-start lg:grid lg:grid-cols-3 lg:gap-3"
            data-v-ff9c5d02=""
          >
            <img
              alt="Logo Defensoría Contribuyente ofrecemos ayuda ante el Servicio de Impuestos Internos (SII)"
              :src="logo_denacon"
              class="max-h-28 w-[275px] object-contain pb-4 lg:col-span-2"
              data-v-ff9c5d02=""
            />
            <img
              alt="min_hacienda (1).svg"
              :src="logo_hacienda"
              class="max-h-28 object-contain lg:mx-auto"
              data-v-ff9c5d02=""
            />
          </div>
          <div class="hidden lg:block" data-v-ff9c5d02="">
            <span
              class="mb-1 text-sm font-semibold text-white"
              data-v-ff9c5d02=""
              >© 2024 Defensoría del Contribuyente, Santiago de Chile</span
            >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKkAAAALBAMAAAD2L7D5AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAA9QTFRFD2m06zxGD2m06zxGAAAAtH5Z2AAAAAV0Uk5TPDz//wAx+92wAAAAJUlEQVR4nGNgIAowCpICBBiUiALKxqQAg1FTR00dUqa60AA4AAC4vpqlIzDtCQAAAABJRU5ErkJggg=="
              data-v-ff9c5d02=""
            />
          </div>
        </div>
        <div
          class="text-left gap-10 space-y-10 pb-12 lg:grid lg:w-6/12 lg:grid-cols-3 lg:justify-between lg:space-y-0 xl:w-9/12 xl:pl-6"
          data-v-ff9c5d02=""
        >
          <div class="py space-y-6 text-white" data-v-ff9c5d02="">
            <h5 class="text-lg font-medium" data-v-ff9c5d02="">Secciones</h5>
            <ul
              class="space-y-4 text-lg text-white underline"
              data-v-ff9c5d02=""
            >
              <li data-v-ff9c5d02="">
                <a
                  href="https://dedecon.cl/conozca-la-defensoria"
                  data-v-ff9c5d02=""
                  class="text-white"
                  >¿Quiénes somos?</a
                >
              </li>
              <li data-v-ff9c5d02="">
                <a
                  href="https://dedecon.cl/#servicios"
                  data-v-ff9c5d02=""
                  class="text-white"
                  >Nuestros servicios</a
                >
              </li>
              <li data-v-ff9c5d02="">
                <a
                  href="https://dedecon.cl/ayuda"
                  data-v-ff9c5d02=""
                  class="text-white"
                  >¿Cómo te ayudamos?</a
                >
              </li>
            </ul>
          </div>
          <div class="space-y-6 text-white" data-v-ff9c5d02="">
            <h5 class="text-lg font-medium" data-v-ff9c5d02="">Ayuda</h5>
            <ul class="space-y-4 text-lg underline" data-v-ff9c5d02="">
              <li data-v-ff9c5d02="">
                <a
                  href="https://dedecon.cl/ayuda/preguntas-frecuentes"
                  data-v-ff9c5d02=""
                  class="text-white"
                  >Preguntas Frecuentes</a
                >
                <!---->
              </li>
              <li data-v-ff9c5d02="">
                <a
                  href="https://dedecon.cl/politica-de-privacidad"
                  data-v-ff9c5d02=""
                  class="text-white"
                  >Políticas de privacidad</a
                >
                <!---->
              </li>
              <li data-v-ff9c5d02="">
                <a
                  href="https://dedecon.cl/condiciones-de-uso"
                  data-v-ff9c5d02=""
                  class="text-white"
                  >Condiciones de uso</a
                >
                <!---->
              </li>
              <li data-v-ff9c5d02="">
                <!---->
                <a
                  class="text-white"
                  target="_blank"
                  href="https://www.leylobby.gob.cl/instituciones/AE033"
                  data-v-ff9c5d02=""
                  >Ley del Lobby</a
                >
              </li>
              <li data-v-ff9c5d02="">
                <!---->
                <a
                  class="text-white"
                  target="_blank"
                  href="https://www.portaltransparencia.cl/PortalPdT/pdtta?codOrganismo=AE033"
                  data-v-ff9c5d02=""
                  >Ley de Transparencia</a
                >
              </li>
            </ul>
          </div>
          <div class="space-y-6 text-white" data-v-ff9c5d02="">
            <h5 class="text-lg font-medium" data-v-ff9c5d02="">
              Redes Sociales
            </h5>
            <div data-v-ff9c5d02="">
              <ul class="space-y-4 text-lg underline">
                <li>
                  <a
                    class="text-white"
                    href="https://www.instagram.com/dedeconchile/"
                    target="_blank"
                    ><img
                      :src="instagram"
                      class="mr-2 inline-block"
                    />Instagram</a
                  >
                </li>
                <li>
                  <a
                    class="text-white"
                    href="https://www.linkedin.com/company/defensoria-del-contribuyente"
                    target="_blank"
                    ><img
                      :src="linkedin"
                      class="mr-2 inline-block"
                    />LinkedIn</a
                  >
                </li>
                <li>
                  <a
                    class="text-white"
                    href="https://www.facebook.com/dedeconchile"
                    target="_blank"
                    ><img
                      :src="facebook"
                      class="mr-2 inline-block"
                    />Facebook</a
                  >
                </li>
                <li>
                  <a
                    class="text-white"
                    href="https://www.youtube.com/@defensoriadelcontribuyente"
                    target="_blank"
                    ><img :src="youtube" class="mr-2 inline-block" />YouTube</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="lg:hidden text-left" data-v-ff9c5d02="">
          <div class="clearfix"></div>
          <span class="mb-1 text-sm font-semibold text-white" data-v-ff9c5d02=""
            >© 2024 Defensoría del Contribuyente, Santiago de Chile</span
          >
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKkAAAALBAMAAAD2L7D5AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAA9QTFRFD2m06zxGD2m06zxGAAAAtH5Z2AAAAAV0Uk5TPDz//wAx+92wAAAAJUlEQVR4nGNgIAowCpICBBiUiALKxqQAg1FTR00dUqa60AA4AAC4vpqlIzDtCQAAAABJRU5ErkJggg=="
            data-v-ff9c5d02=""
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FotterComponent",
  data() {
    return {
      logo_denacon: process.env.VUE_APP_FOOTER_LOGO_DENACON
        ? process.env.VUE_APP_FOOTER_LOGO_DENACON
        : "",
      logo_hacienda: process.env.VUE_APP_FOOTER_LOGO_HACIENDA
        ? process.env.VUE_APP_FOOTER_LOGO_HACIENDA
        : "",
      instagram: process.env.VUE_APP_FOOTER_INSTAGRAM_IMG
        ? process.env.VUE_APP_FOOTER_INSTAGRAM_IMG
        : "",
      youtube: process.env.VUE_APP_FOOTER_YOUTUBE_IMG
        ? process.env.VUE_APP_FOOTER_YOUTUBE_IMG
        : "",
      linkedin: process.env.VUE_APP_FOOTER_LINKEDIN_IMG
        ? process.env.VUE_APP_FOOTER_LINKEDIN_IMG
        : "",
      facebook: process.env.VUE_APP_FOOTER_FACEBOOK_IMG
        ? process.env.VUE_APP_FOOTER_FACEBOOK_IMG
        : ""
    };
  }
};
</script>

<style scoped></style>
