import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import VueAxios from "vue-axios";
import UUID from "vue-uuid";
import { LoadingPlugin } from 'vue-loading-overlay';


const app = createApp(App);
app.use(router);
app.use(UUID);
app.use(VueAxios, axios);
app.use(LoadingPlugin);
app.mount('#app');
