<template>
  <header-component></header-component>
  <div class="container-full pt-5 pb-5 mb-5">
    <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
    <div class="container-page">
      <div class="found w-screen">
        <div class="container-main-home">
          <div class="display">
            <img class="imagen-page md:w-1/2" src="https://claveunica.denacon.cl/img/imagen.ddeddb96.svg" alt="logo" />
            <div class="box-parrafo">
              <div class="card md:w-full">
                <div class="card-body">
                  <p class="title-form">Registrarse</p>
                  <br />
                  <p class="subtitle-form">
                    Parece que es tu primera vez en nuestro sitio, por favor
                    indícanos un correo para poder registrarte.
                  </p>
                  <div class="container-centrar">
                    <div class="my-5">
                      <div class="input-button">
                        <label class="label-email ml-2">Correo electrónico *</label>
                        <input
                          class="input-email mb-4 placeholder:italic placeholder:text-slate-400 block bg-white px-2 border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          type="email" name="email" id="email" required v-model="payload.user.email"
                          v-on:keyup.enter.prevent="createUserZendesk" />
                        <!-- <button class="button-page">Continuar</button> 
                          
                        -->
                        <input type="button" class="button-page btn-cu btn-m btn-color-estandar" :disabled="disabledBtn"
                          v-on:click.left="createUserZendesk" value="Continuar" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix w-full"></div>
  </div>
  <div class="clearfix"></div>
  <fotter-component></fotter-component>
</template>

<script>
import FotterComponent from "@/components/FotterComponent";
import HeaderComponent from "@/components/HeaderComponent";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  name: "RegisterView",
  components: {
    HeaderComponent,
    FotterComponent,
    Loading
  },
  data: function () {
    return {
      processing: false,
      parameters: null,
      userInfo: null,
      payload: {
        user: {
          name: null,
          email: null,
          role: "end-user",
          external_id: null
        },
        form_id: null
      },
      isLoading: false,
      fullPage: true
    };
  },
  methods: {
    async getUserInfo() {
      let tributaria = localStorage.getItem("tributaria");
      if (tributaria == "true") {
        await this.axios
          .get(
            process.env.VUE_APP_API_URL +
            "user/infoct?access_token=" +
            this.parameters.access_token
          )
          .then((response) => {
            this.userInfo = response.data.userInfo.data;
          });
      } else {

        await this.axios
          .get(
            process.env.VUE_APP_API_URL +
            "user/info?access_token=" +
            this.parameters.access_token
          )
          .then((response) => {
            this.userInfo = response.data.userInfo.data;
          });
      }
    },
    async createUserZendesk() {
      this.isLoading = true;
      if (this.processing === true) {
        return;
      }

      this.processing = true;

      if (
        this.payload.user.email == null ||
        this.payload.user.email === "" ||
        this.payload.user.email == undefined
      ) {
        alert("Debe ingresar el correo electrónico de manera obligatoria");
        this.isLoading = false;
        this.processing = false;
      } else {
        await this.axios
          .post(
            process.env.VUE_APP_API_URL + "user",
            JSON.stringify(this.payload),
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          )
          .then((response) => {
            if (response.data.userInfo.errors === false) {
              window.location.href = response.data.redirect_url;
            } else {
              alert(
                "Su correo electrónico ya se encuentra registrado y no es posible continuar con la navegación, puede comunicarse con la casilla contacto@dedecon.cl para resolver esta situación."
              );
            }
          })
          .catch((error) => {
            if (error) {
              alert(
                "Ocurrio un error al intentar ingresar el correo electrónico ingresado."
              );
            }
          });
        this.isLoading = false;

        this.processing = false;
      }
    }
  },
  async mounted() {
    this.parameters = this.$route.query;

    let tributaria = localStorage.getItem("tributaria");

    await this.getUserInfo();
    let identityCard;
    let name = "";
    let lastName = "";

    if (tributaria == "true") {
      identityCard = JSON.stringify(this.userInfo.rut);
      // eslint-disable-next-line no-prototype-builtins
      if (this.userInfo.hasOwnProperty('razon_social')) {
        if (this.userInfo.razon_social != '') {
          name = JSON.stringify(this.userInfo.razon_social);
        } else {
          name = JSON.stringify(this.userInfo.nombres);
          lastName = JSON.stringify(
            this.userInfo.apellido_paterno + " " + this.userInfo.apellido_materno
          );
        }
      } else {
        name = JSON.stringify(this.userInfo.nombres);
        lastName = JSON.stringify(
          this.userInfo.apellido_paterno + " " + this.userInfo.apellido_materno
        );
      }

    } else {
      identityCard = JSON.stringify(this.userInfo.RolUnico.numero);
      name = JSON.stringify(this.userInfo.name.nombres.join(" "));
      lastName = JSON.stringify(this.userInfo.name.apellidos.join(" "));
    }

    this.payload.user.name = `${name.replace(/["']/g, "")} ${lastName.replace(
      /["']/g,
      ""
    )}`;
    this.payload.user.external_id = identityCard;
    this.payload.form_id = localStorage.getItem("form_id");
    this.payload.params = JSON.parse(localStorage.getItem("params"));
  }
};
</script>

<style>
@import "@/assets/styles/cu.min.css";
@import "@/assets/styles/styles.css";
</style>
