<template>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      rel="shortcut icon"
      href="https://www.tgr.cl/wp-content/uploads/2019/10/favicon-1.ico"
      type="image/x-icon"
    />
    <link
      href="https://cdn.jsdelivr.net/npm/@mdi/font@5.9.55/css/materialdesignicons.min.css"
      rel="stylesheet"
    />

    <title>Home</title>
  </head>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
  font-family: Roboto, system-ui;
}
</style>
