<template>
  <div>
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
  </div>
</template>

<script>
import router from "@/router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  name: "RedirectView",
  data: function () {
    return {
      form_id: null,
      info: null,
      isLoading: false,
      fullPage: true
    };
  },
  components: {
    Loading
  },
  methods: {
    queryString(obj) {
      return Object.keys(obj)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        )
        .join("&");
    },
    getUserData() {
      this.isLoading = true;
      let params = localStorage.getItem("params");
      let tributaria = localStorage.getItem("tributaria");
      let queryParams = "";

      if (params != undefined || params != null) {
        queryParams = this.queryString(JSON.parse(params));
      }

      if (tributaria == "true") {

        this.axios
          .get(
            process.env.VUE_APP_API_URL +
              "user/ct?code=" +
              this.info.code +
              "&state=" +
              this.info.state +
              "&form_id=" +
              this.form_id +
              "&" +
              queryParams
          )
          .then((response) => {
            this.isLoading = false;
            window.location.href = response.data.redirect_url;
          });
      } else {

        this.axios
          .get(
            process.env.VUE_APP_API_URL +
              "user?code=" +
              this.info.code +
              "&state=" +
              this.info.state +
              "&form_id=" +
              this.form_id +
              "&" +
              queryParams
          )
          .then((response) => {
            this.isLoading = false;
            window.location.href = response.data.redirect_url;
          });
      }
    }
  },
  mounted() {
    let state = localStorage.getItem("state");
    this.info = this.$route.query;
    this.form_id = localStorage.getItem("form_id");

    if (state !== this.info.state) {
      alert(
        "Existio un error en la solicitud de inicio de sesión debera iniciar nuevamente el proceso"
      );
      router.push({ name: "home", query: { form_id: this.form_id } });
    }

    this.getUserData();
  }
};
</script>

<style scoped></style>
