<template>
  <header class="block">
    <div class="pb-2">
      <!---->
      <div class="gob-markup relative top-0 left-12 ml-12 lg:left-28 lg:ml-24">
        <img src="https://dedecon.cl/_nuxt/img/Gob.438bd44.svg" alt="" />
      </div>
    </div>

    <div class="container mx-auto" data-v-7ebe171c="">
      <div
        class="hidden flex-row-reverse items-center lg:flex"
        data-v-7ebe171c=""
      >
        <div class="ml-4" data-v-7ebe171c="">
          <ul class="flex">
            <li>
              <a href="https://www.instagram.com/dedeconchile/" target="_blank">
                <img
                  :src="instagram"
                  class="mx-1 inline-block invert dark:invert-0"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/defensoria-del-contribuyente"
                target="_blank"
              >
                <img
                  :src="linkedin"
                  class="mx-1 inline-block invert dark:invert-0"
                />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/dedeconchile" target="_blank">
                <img
                  :src="facebook"
                  class="mx-1 inline-block invert dark:invert-0"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@defensoriadelcontribuyente"
                target="_blank"
              >
                <img
                  :src="youtube"
                  class="mx-1 inline-block invert dark:invert-0"
                />
              </a>
            </li>
          </ul>
        </div>
        <div class="flex space-x-2 hidden" data-v-7ebe171c="">
          <button class="rounded border border-black p-2 dark:invert">
            <svg
              width="20"
              height="15"
              viewBox="0 0 20 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.05273 2.17578L2.80469 14.5H0.236328L5.58789 0.28125H7.22852L7.05273 2.17578ZM10.6074 14.5L6.34961 2.17578L6.16406 0.28125H7.81445L13.1855 14.5H10.6074ZM10.4023 9.22656V11.1699H2.66797V9.22656H10.4023ZM19.5371 2.7832V4.16016H13.6895V2.7832H19.5371ZM17.3574 0.433594V6.64453H15.875V0.433594H17.3574Z"
                fill="black"
              ></path>
            </svg>
          </button>
          <button class="rounded border border-black p-2 dark:invert">
            <svg
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.14844 2.17578L2.90039 14.5H0.332031L5.68359 0.28125H7.32422L7.14844 2.17578ZM10.7031 14.5L6.44531 2.17578L6.25977 0.28125H7.91016L13.2812 14.5H10.7031ZM10.498 9.22656V11.1699H2.76367V9.22656H10.498ZM17.207 3.23438V4.45898H14.002V3.23438H17.207Z"
                fill="black"
              ></path>
            </svg>
          </button>
          <button
            class="rounded border border-black p-2 text-black dark:border-white dark:bg-primary dark:text-white"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
              class="fill-current"
            >
              <path
                d="M9.00033 17.3333C13.6003 17.3333 17.3337 13.6 17.3337 8.99996C17.3337 4.39996 13.6003 0.666626 9.00033 0.666626C4.40033 0.666626 0.666992 4.39996 0.666992 8.99996C0.666992 13.6 4.40033 17.3333 9.00033 17.3333ZM9.83366 2.39163C13.117 2.79996 15.667 5.59996 15.667 8.99996C15.667 12.4 13.1253 15.2 9.83366 15.6083V2.39163Z"
              ></path>
            </svg>
          </button>
        </div>
        <span class="mr-2 text-sm text-gray2 hidden" data-v-7ebe171c=""
          >Accesibilidad</span
        >
      </div>
      <header class="flex items-center px-5 xl:px-0" data-v-7ebe171c="">
        <nav class="flex w-full justify-between lg:flex-col" data-v-7ebe171c="">
          <div class="lg:hidden" data-v-7ebe171c="">
            <button
              data-v-7ebe171c=""
              data-collapse-toggle="menu-container"
              aria-controls="menu-container"
              aria-expanded="false"
              v-on:click="showMenu"
            >
              <i
                class="mdi mdi-menu text-3xl dark:text-darkprimary"
                data-v-7ebe171c=""
              ></i>
            </button>
          </div>
          <div
            class="flex w-full items-center justify-center lg:justify-between"
            data-v-7ebe171c=""
          >
            <a
              href="https://dedecon.cl/"
              aria-current="page"
              class="nuxt-link-exact-active nuxt-link-active"
              data-v-7ebe171c=""
            >
              <img
                :src="logo_def"
                alt="Logo Defensoría Contribuyente ofrecemos ayuda ante el Servicio de Impuestos Internos (SII)"
                class="h-14 max-h-16 pt-2 dark:hidden lg:h-auto lg:w-[250px]"
                data-v-7ebe171c=""
              />
              <img
                :src="logo_def"
                alt="Logo Defensoría Contribuyente ofrecemos ayuda ante el Servicio de Impuestos Internos (SII)"
                class="hidden h-14 max-h-16 dark:block lg:h-auto lg:w-[250px]"
                data-v-7ebe171c=""
              />
            </a>
          </div>
          <ul
            class="hidden space-x-4 py-6 text-center text-base font-medium text-gray2 lg:flex lg:items-center xl:space-x-6 xl:text-lg 2xl:text-lg"
            data-v-7ebe171c=""
          >
            <li class="relative menu-item-active" data-v-7ebe171c="">
              <a
                href="https://dedecon.cl/"
                aria-current="page"
                class="nuxt-link-exact-active nuxt-link-active"
                data-v-7ebe171c=""
                >Inicio</a
              >
            </li>
            <li class="relative" data-v-7ebe171c="">
              <a
                href="https://dedecon.cl/conozca-la-defensoria"
                data-v-7ebe171c=""
                class=""
                >¿Quiénes somos?</a
              >
            </li>
            <li class="relative" data-v-7ebe171c="">
              <a
                href="https://dedecon.cl/#servicios"
                data-v-7ebe171c=""
                class=""
                >Nuestros Servicios</a
              >
            </li>
            <li class="relative" data-v-7ebe171c="">
              <a href="https://dedecon.cl/ayuda" data-v-7ebe171c="" class=""
                >¿Cómo te ayudamos?</a
              >
            </li>
            <li data-v-7ebe171c=""></li>
          </ul>
          <div class="lg:hidden" data-v-7ebe171c=""></div>
        </nav>
        <div
          id="menu-container"
          class="fixed top-0 left-0 z-50 w-full"
          :class="[isShowMenu ? 'flex fade-in' : 'hidden fade-out']"
          data-v-7ebe171c=""
          v-on:click="showMenu"
        >
          <div
            id="menu"
            class="h-screen w-10/12 space-y-10 overflow-y-auto bg-white py-20 px-3 dark:bg-black2"
            :class="[isShowMenu ? 'slide-in' : 'slide-out']"
            data-v-7ebe171c=""
          >
            <div data-v-7ebe171c="">
              <a
                href="https://dedecon.cl/"
                aria-current="page"
                class="nuxt-link-exact-active nuxt-link-active"
                data-v-7ebe171c=""
              >
                <img
                  :src="logo_def"
                  alt="Logo Defensoría Contribuyente ofrecemos ayuda ante el Servicio de Impuestos Internos (SII)"
                  class="lg:h-autos h-14 max-h-16 dark:hidden"
                  data-v-7ebe171c=""
                />
                <img
                  :src="logo_denacon"
                  alt="Logo Defensoría Contribuyente ofrecemos ayuda ante el Servicio de Impuestos Internos (SII)"
                  class="hidden h-14 max-h-16 dark:block lg:h-auto"
                  data-v-7ebe171c=""
                />
              </a>
            </div>
            <ul class="space-y-8 text-lg text-gray" data-v-7ebe171c="">
              <li class="space-y-4" data-v-7ebe171c="">
                <div
                  class="flex items-center space-x-4 font-bold hover:underline"
                  data-v-7ebe171c=""
                >
                  <div class="flex w-8 justify-center" data-v-7ebe171c="">
                    <div class="w-12" data-v-7ebe171c=""></div>
                  </div>
                  <a
                    href="https://dedecon.cl/conozca-la-defensoria"
                    data-v-7ebe171c=""
                    class=""
                    >¿Quiénes somos?</a
                  >
                </div>
              </li>
              <li class="space-y-4" data-v-7ebe171c="">
                <div
                  class="flex items-center space-x-4 font-bold hover:underline"
                  data-v-7ebe171c=""
                >
                  <div class="flex w-8 justify-center" data-v-7ebe171c="">
                    <div class="w-12" data-v-7ebe171c=""></div>
                  </div>
                  <a
                    href="https://dedecon.cl/#servicios"
                    data-v-7ebe171c=""
                    class=""
                    >Nuestros Servicios</a
                  >
                </div>
              </li>
              <li class="space-y-4" data-v-7ebe171c="">
                <div
                  class="flex items-center space-x-4 font-bold hover:underline"
                  data-v-7ebe171c=""
                >
                  <div class="flex w-8 justify-center" data-v-7ebe171c="">
                    <div class="w-12" data-v-7ebe171c=""></div>
                  </div>
                  <a href="https://dedecon.cl/ayuda" data-v-7ebe171c="" class=""
                    >¿Cómo te ayudamos?</a
                  >
                </div>
              </li>
              <li
                class="absolute bottom-10 right-0 left-0 mx-auto flex flex-col items-center justify-center space-x-3"
                data-v-7ebe171c=""
              >
                <div class="flex items-center" data-v-7ebe171c="">
                  <span
                    class="px-4 text-[0.98rem] text-gray2 hidden"
                    data-v-7ebe171c=""
                    >Accesibilidad</span
                  >
                  <div class="flex space-x-2 hidden" data-v-7ebe171c="">
                    <button class="rounded border border-black p-2 dark:invert">
                      <svg
                        width="20"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.05273 2.17578L2.80469 14.5H0.236328L5.58789 0.28125H7.22852L7.05273 2.17578ZM10.6074 14.5L6.34961 2.17578L6.16406 0.28125H7.81445L13.1855 14.5H10.6074ZM10.4023 9.22656V11.1699H2.66797V9.22656H10.4023ZM19.5371 2.7832V4.16016H13.6895V2.7832H19.5371ZM17.3574 0.433594V6.64453H15.875V0.433594H17.3574Z"
                          fill="black"
                        ></path>
                      </svg>
                    </button>
                    <button class="rounded border border-black p-2 dark:invert">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.14844 2.17578L2.90039 14.5H0.332031L5.68359 0.28125H7.32422L7.14844 2.17578ZM10.7031 14.5L6.44531 2.17578L6.25977 0.28125H7.91016L13.2812 14.5H10.7031ZM10.498 9.22656V11.1699H2.76367V9.22656H10.498ZM17.207 3.23438V4.45898H14.002V3.23438H17.207Z"
                          fill="black"
                        ></path>
                      </svg>
                    </button>
                    <button
                      class="rounded border border-black p-2 text-black dark:border-white dark:bg-primary dark:text-white"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        xmlns="http://www.w3.org/2000/svg"
                        class="fill-current"
                      >
                        <path
                          d="M9.00033 17.3333C13.6003 17.3333 17.3337 13.6 17.3337 8.99996C17.3337 4.39996 13.6003 0.666626 9.00033 0.666626C4.40033 0.666626 0.666992 4.39996 0.666992 8.99996C0.666992 13.6 4.40033 17.3333 9.00033 17.3333ZM9.83366 2.39163C13.117 2.79996 15.667 5.59996 15.667 8.99996C15.667 12.4 13.1253 15.2 9.83366 15.6083V2.39163Z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="mt-4" data-v-7ebe171c="">
                  <ul class="flex">
                    <li>
                      <a
                        href="https://www.instagram.com/dedeconchile/"
                        target="_blank"
                        ><img
                          :src="instagram"
                          class="mx-1 inline-block invert dark:invert-0"
                      /></a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/defensoria-del-contribuyente"
                        target="_blank"
                        ><img
                          :src="linkedin"
                          class="mx-1 inline-block invert dark:invert-0"
                      /></a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/dedeconchile"
                        target="_blank"
                        ><img
                          :src="facebook"
                          class="mx-1 inline-block invert dark:invert-0"
                      /></a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@defensoriadelcontribuyente"
                        target="_blank"
                        ><img
                          :src="youtube"
                          class="mx-1 inline-block invert dark:invert-0"
                      /></a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      isShowMenu: false,
      isback: true,
      logo_def: process.env.VUE_APP_HEADER_LOGO_DEF_CON
        ? process.env.VUE_APP_HEADER_LOGO_DEF_CON
        : "",
      instagram: process.env.VUE_APP_HEADER_INSTAGRAM_IMG
        ? process.env.VUE_APP_HEADER_INSTAGRAM_IMG
        : "",
      youtube: process.env.VUE_APP_HEADER_YOUTUBE_IMG
        ? process.env.VUE_APP_HEADER_YOUTUBE_IMG
        : "",
      linkedin: process.env.VUE_APP_HEADER_LINKEDIN_IMG
        ? process.env.VUE_APP_HEADER_LINKEDIN_IMG
        : "",
      facebook: process.env.VUE_APP_HEADER_FACEBOOK_IMG
        ? process.env.VUE_APP_HEADER_FACEBOOK_IMG
        : "",
      logo_denacon: process.env.VUE_APP_HEADER_LOGO_DENACON
        ? process.env.VUE_APP_HEADER_LOGO_DENACON
        : ""
    };
  },
  methods: {
    showMenu() {
      this.isShowMenu = !this.isShowMenu;
    }
  }
};
</script>
<style>
@import "@/assets/styles/cu.min.css";
@import "@/assets/styles/styles.css";
@import "@/assets/styles/ct.css";
</style>
<style scoped></style>
